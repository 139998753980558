import React, { useState, useContext, useMemo } from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

import { SaharahContext } from "@/contexts/Saharah"

import SaharahLayout from "@/components/SaharahLayout"
import FloorWallCard from "@/components/FloorWallCard"
import NoMatch from "@/components/NoMatch"
import { Tabs,MultiLineTabs, TextTab } from "@/components/Tabs"

import createNodeId from "@/utils/createNodeId"

const SaharahFloorWallPage = () => {
  const queryData = useStaticQuery(graphql`
    query {
      allSaharahYaml {
        nodes {
          floors {
            name {
              en
              ja
              ko
              zh_hans
              zh_hant
            }
            icon {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            VFX
          }
        }
      }
    }
  `)
  const { floors } = queryData.allSaharahYaml.nodes[0]

  const formatFloors = floors.map((floor) => ({
    ...floor,
    isCollected: false,
    id: createNodeId(floor.name.en, `acnh`),
  }))

  const [value, setValue] = useState(0)

  const [value2, setValue2] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleChange2 = (event, newValue) => {
    setValue2(newValue)
  }

  return (
    <SaharahLayout>
      <MultiLineTabs value={value} onChange={handleChange}>
        <TextTab label="all" />
        <TextTab label="vfxFloor" />
        <TextTab label="noVfxFloor" />
      </MultiLineTabs>
      <MultiLineTabs value={value2} onChange={handleChange2}>
        <TextTab label="all" />
        <TextTab label="notCollected" />
        <TextTab label="collected" />
      </MultiLineTabs>
      <FloorWallCards
        tabs={{ value, value2 }}
        floors={formatFloors}
      />
    </SaharahLayout>
  )
}

export default SaharahFloorWallPage

const FloorWallCards = ({ floors, tabs }) => {
  const { saharah } = useContext(SaharahContext)

  const currentFloors = useMemo(() => {
    const { value, value2 } = tabs
    const floorsHasMarked = floors.map((floor) => {
      const floorHasMarked = saharah.filter((id) => id === floor.id)
      return {
        ...floor,
        isCollected: floorHasMarked.length ? true : false,
      }
    })
    let floorsArr = [...floorsHasMarked]

    if (value === 1) {
      floorsArr = floorsArr.filter((floor) => floor.VFX)
    } else if (value === 2) {
      floorsArr = floorsArr.filter((floor) => !floor.VFX)
    }

    if (value2 === 1) {
      floorsArr = floorsArr.filter((floor) => !floor.isCollected)
    } else if (value2 === 2) {
      floorsArr = floorsArr.filter((floor) => floor.isCollected)
    }

    return floorsArr
  }, [saharah, floors, tabs])

  return (
    <StyledFloorWallCards>
      {currentFloors.length ? (
        <div className="floor_cards">
          {currentFloors.map((floor) => (
            <FloorWallCard floorwall={floor} key={floor.name.en} />
          ))}
        </div>
      ) : (
        <NoMatch />
      )}
    </StyledFloorWallCards>
  )
}

const StyledFloorWallCards = styled.div`
  .floor_cards {
    display: flex;
    flex-wrap: wrap;
    padding: 10px 15px;
    @media (max-width: 450px) {
      padding: 10px 0;
    }
  }
`